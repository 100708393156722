import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert'; 


function Auth({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      navigate('/projects'); 
    }
  }, [navigate, cookies]);

  const submitValue = async (ev) => {
    ev.preventDefault();

    const user = {
      username,
      pass: password,
    };

    try {
      const response = await fetch('https://o59s9a012jd.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        referrerPolicy: 'unsafe-url',
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        swal("Error", errorResponse.error || 'Имя пользователя или пароль неверны', "error");
      } else {
        const result = await response.json();

        if (result.token) {
          cookies.set('token', result.token, { path: '/' });
          cookies.set('username', username, { path: '/' });

          onLogin(username);  
          navigate('/projects');  
        } else {
          swal("Error", 'Имя пользователя или пароль неверны', "error");
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="login_page">
        <form onSubmit={submitValue} id="loginForm">
          <div className="header_logo">
            <span>
              <b>AlfaSearch</b> Admin Panel
            </span>
          </div>
          <input
            name="name"
            type="text"
            placeholder="Логин"
            onChange={(e) => setUsername(e.target.value)}
            id="login"
            required=""
          />
          <input
            name="password"
            type="text"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Пароль"
            id="password"
            required=""
          />
          <div className="form_button">
            <button type="submit">Войти</button>
          </div>
          {error && <div className="fail">{error}</div>}
        </form>
      </div>
    </>
  );
}

export default Auth;
